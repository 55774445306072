import {useState, useEffect,useRef} from "react";
import logo from './logo.svg';
import './App.css';
import ReactPlayer from 'react-player'
import Lottie from 'react-lottie-player'

import VideoImage from "./assets2/videoBack.png";
import BrandLogo from "./assets2/brandIcon.png";
import Head from "./assets2/head.png"
import Ring from "./assets2/backRing.png"
import Banner1 from "./assets2/banner1.png"
import Banner2 from "./assets2/banner2.png"
import Case from "./assets2/case.png"
import Company from "./assets2/company.png"
import Design from "./assets2/design.png"
import Events from "./assets2/Events.png"
import FormBack from "./assets2/formBack.png"
import Project from "./assets2/project.png"
import Whatsapp from "./assets2/whatsapp.png"
import Expert from "./assets2/experts.png"
import FormPc from "./assets2/formSubmitPc.png"
import FormMobile from "./assets2/formSubmitMobile.png"
import CloseButn from "./assets2/close.png"
import Profile1 from "./assets2/profile/1.png"
import Profile2 from "./assets2/profile/2.png"
import Profile3 from "./assets2/profile/3.png"
import Profile4 from "./assets2/profile/4.png"
import Profile5 from "./assets2/profile/5.png"
import Profile6 from "./assets2/profile/6.png"
import Profile7 from "./assets2/profile/7.png"
import Profile8 from "./assets2/profile/8.png"
import Profile9 from "./assets2/profile/9.png"
import Profile10 from "./assets2/profile/10.png"

import FormBack1 from "./assets2/formBack1.png"
import footerMobile from "./assets2/footerMobile.png"
import Project1 from "./assets2/projects1/1.gif"
import Project2 from "./assets2/projects1/2.gif"
import Project3 from "./assets2/projects1/3.gif"
import Project4 from "./assets2/projects1/4.gif"
import Project5 from "./assets2/projects1/5.gif"
import Project6 from "./assets2/projects1/6.gif"

import Social1 from "./assets2/social/1.png"
import Social2 from "./assets2/social/2.png"
import Social3 from "./assets2/social/3.png"
import Social4 from "./assets2/social/4.png"

import ScrollDown from "./assets2/scroll-down.gif"
import {scrollToTargetAdjusted} from "./components/usageFunctions.js";
import { useInView } from "react-intersection-observer";
import { InView } from "react-intersection-observer";
import ScrollLottie from "./assets2/Scroll down/data.json";
import { useHistory } from "react-router-dom";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function MainPage() {
  const playerRef = useRef();
  let history = useHistory();
  const [showFormSubmitPc,setShowFormSubmitPc] = useState(false);
  const [showFormSubmitMobile,setShowFormSubmitMobile] = useState(false);
  const [play,setPlay] = useState(false);
  const [inView, setInView] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [project, setproject] = useState([
    {
      image: Project1,
      tag: "NextSpace"
    },
    {
      image: Project2,
      tag: "DJ Warehouse"
    },
    {
      image: Project3,
      tag: "ZS Negotiation Academy"
    },
    {
      image: Project4,
      tag: "Ilex"
    },
    {
      image: Project5,
      tag: "Formica Virtual Experience"
    },
    {
      image: Project6,
      tag: "DJ Breakout Room"
    },
  ])
  const [profile, setprofile] = useState([
    {
      image: Profile1,
    },
    {
      image: Profile2,
    },
    {
      image: Profile3,
    },
    {
      image: Profile4,
    },
    {
      image: Profile5,
    },
    {
      image: Profile6,
    },
    {
      image: Profile7,
    },
    {
      image: Profile8,
    },
    {
      image: Profile9,
    },
    {
      image: Profile10,
    },
  ])
  const [social, setsocial] = useState([
    {
      image: Social1,
      link:"https://www.instagram.com/digitaljalebi/?hl=en"
    },
    {
      image: Social2,
      link:"https://hello-digitaljalebi.medium.com/"
    },
    {
      image: Social3,
      link:"https://in.linkedin.com/company/digital-jalebi"
    },
    {
      image: Social4,
      link:"https://www.facebook.com/digitaljalebi/"
    }
  ])
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  console.log(windowDimensions);

  return (
    <div className="main-page" inView={inView} style={{overflow: 'hidden',width: '100%',maxHeight:""}}>
      {/* NAVBAR */}
      
      
      
      {/* HEADER */}
      <div className="header ">
      <div className="navbar wid-center">
        <img src={BrandLogo} className="nav-logo"/>

        <div className="nav-tags clr-white">
          <div className="contact-us-link" onClick={() => scrollToTargetAdjusted("form",0)}><p>Contact Us</p></div>
        </div>
      </div>
        <div>
          <img src={Ring} className="ring" />
          <img src={Head} className="head1" />
          <img src={Events} className="head2" />
          <Lottie
            loop
            animationData={ScrollLottie}
            play
            className="scroll-down"
            // style={{ width: 150, height: 150 }}
          />
          {/* <img src={ScrollDown} className="scroll-down" /> */}
        </div>
      </div>
      
      
      {/* VIDEO */}
      <InView 
        onChange={(inView, entry) => {
          if (inView === true)
          { 
            console.log("true")
            setPlay(true);
            playerRef.current.play()
            // playerRef.current.muted = true;
            // playerRef.current.autoplay = true;
          }
          else if (inView === false && play === true)
          {
            console.log("false") 
            setPlay(false);
            playerRef.current.pause()
            // playerRef.current.muted = true;
            // playerRef.current.autoplay = true;
          }
        }}
      >
        <div className="video">
          <img src={VideoImage} className="video-image" />
          <ReactPlayer
          // ref={playerRef}
          playing	={play}
          autoplay
          muted={true}
          loop={true}
          // url='https://vimeo.com/648504020/595aa62994'
          url='https://vimeo.com/679456975/2a944b48da' 
          className="react-video-player" 
          config={{
            vimeo:{
              autoplay:true
            }
          }}
          width='100vw' height={windowDimensions.width>1200?'80vh':"30vh"}/>
        </div>
      </InView>


      <div className="form-submit-cont" style={{display:showFormSubmitPc?"block":"none"}}>
        <img src ={FormPc} className="form-submit-image-pc"/>
        {/* <img src ={CloseButn} className="close-btn" onClick={() => {
          setShowFormSubmitPc(false)
        }}/> */}
      </div>

      <div className="form-submit-cont" style={{display:showFormSubmitMobile?"block":"none"}}>
        <img src ={FormMobile} className="form-submit-image-pc"/>
        <img src ={CloseButn} className="close-btn" onClick={() => {
          setShowFormSubmitMobile(false)
        }}/>
      </div>
      
      {/* FORM-PC*/}
      <div className="form-cont" id="form">
        <div className="form wid-center form-content" >
          {/* LEFT */}
          <div className="block-50">
            <img src={FormBack1} className="form-back1-img"/>
            <div className="wid-90 form-left-block">
            <p className="form-heading clr-white">Book a demo to plan your next Metaverse Event!</p>
            {/* <button className="butt-blue">Explore More</button> */}
            </div>
          </div>
    
          {/* RIGHT */}
          <form className="block-50 height-center" onSubmit={(e) => { 
                    e.preventDefault();
                  var myHeaders = new Headers();
                  myHeaders.append("Content-Type", "application/json");

                  var raw = JSON.stringify({
                    "name": name,
                    "message": message,
                    "email": email
                  });
                
                  var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                  };
                
                  fetch("https://googleads-mailer-dot-virtual-event-273009.appspot.com/meta_message", requestOptions)
                    .then(response => response.text())
                    .then(async result => {
                      console.log(result);

                      const data = {
                        Timestamp: new Date(),
                        name: name,
                        email: email,
                        Query: message
                      };
                      
                      // Add one line to the sheet
                      fetch("https://sheet.best/api/sheets/0f7e7ff9-5700-42e2-a529-b776ab5562cb", {
                        method: "POST",
                        mode: "cors",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                      })
                      .then((r) => r.json())
                      .then((data) => {
                        // The response comes here
                        console.log("added to sheets"+data);
                      })
                      .catch((error) => {
                        // Errors are reported there
                        console.log("error adding to sheets"+error);
                      });
                      




                      await setmessage("");
                      await setname("");
                      await setemail("");
                      history.push("/formsubmit")
                    })
                    .catch(error => console.log('error', error));
                }} >
            <div className="form-components-container main-form">
              <div className="imput-feild-container">
                <input type="" name="" required onChange={(e)=>{setname(e.target.value)}} value={name} placeholder="Enter Your Name" className="input-feild"/>
                <input type="email" name="" required onChange={(e)=>{setemail(e.target.value)}} value={email} placeholder="Enter Your Email ID" className="input-feild"/>
                <textarea type="" name="" required onChange={(e)=>{setmessage(e.target.value)}} value={message} placeholder="Write your thoughts" className="textarea-feild"/>
              </div>
              {/* {showFormSubmitLine && <p className="clr-white form-submit-line">Our representative will respond within the next 24 hours.</p>} */}
              <div className="form-butn-container">
                <button className="butt-blue" type="submit">Send Message</button>
                <button className="butt-whatsapp" onClick={() => {
                window.open('https://api.whatsapp.com/send?phone=+918297559743&text=Let%27s%20Connect%20and%20build%20something', '_blank');
              }}><img src={Whatsapp} className="whatsapp-icon"/>Connect on Whatsapp</button>
              </div>
              {/* <div className="whatsapp-container">
                <img src={Whatsapp} className="whatsapp-icon"/>
                <p className="clr-green">Connect on Whatsapp</p>
              </div> */}
            </div>
          </form>
            
        </div>
      </div>
      
      {/* FORM-MOBILE*/}
      <div className="form-mobile wid-center">
        
        {/* LEFT */}
        <div className="block-50">
          <div className="wid-90">
          <p className="form-heading clr-white">Book a demo to plan your next Metaverse Event!</p>
          {/* <button className="butt-blue">Explore More</button> */}
          </div>
        </div>

        {/* RIGHT */}
        <form className="block-50" onSubmit={(e) => { 
              e.preventDefault();
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");

              var raw = JSON.stringify({
                "name": name,
                "message": message,
                "email": email
              });
            
              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };
            
              fetch("https://googleads-mailer-dot-virtual-event-273009.appspot.com/meta_message", requestOptions)
                .then(response => response.text())
                .then(async result => {
                  console.log(result);
                  await setmessage("");
                  await setname("");
                  await setemail("");
                  history.push("/formsubmit")
                })
                .catch(error => console.log('error', error));
            }}>
          <input type="" name="" required onChange={(e)=>{setname(e.target.value)}} value={name} placeholder="Enter Your Name" className="input-feild"/>
          <input type="email" name="" required onChange={(e)=>{setemail(e.target.value)}} value={email} placeholder="Enter Your Email ID" className="input-feild"/>
          <input type="" name="" required onChange={(e)=>{setmessage(e.target.value)}} value={message} placeholder="Write your thoughts" className="input-feild"/>
          <button 
            className="butt-blue form-submit"
            
          >Send Message</button>
          {/* {showFormSubmitLine && <p className="clr-white respond-line">Our representative will respond within the next 24 hours.</p>} */}
          <div className="whatsapp-container" onClick={() => {
                window.open('https://api.whatsapp.com/send?phone=+918297559743&text=Let%27s%20Connect%20and%20build%20something', '_blank');
              }}>
            <img src={Whatsapp} className="whatsapp-icon"/>
            <p className="clr-green">Connect on Whatsapp</p>
          </div>
        </form>

      </div>
      
      {/* OUR PROJECTS */}
      <div className="projects wid-center" id="ourProject">
        <img src={Project} className="project-head"/>
        {project.map( (data,index )=> {
          if((index%2) !== 0)
          return (
            <div className="indi-project wid-center float-right" >
              <div className="project-image-container">
                <img src={data.image} className="project-image"/>
              </div>
              <p className="clr-white" className="project-tag">{data.tag}</p>
            </div>
          )
          else
          return (
            <div className="indi-project wid-center">
              <div className="project-image-container">
                <img src={data.image} className="project-image"/>
              </div>
              <p className="clr-white" className="project-tag">{data.tag}</p>
            </div>
          )
        })}
      </div>
      
      
      {/* DESIGN TOOLS */}
      <div className="design-tools wid-center">
        
        <div className="block-50">
          <div className="design-content-container">
            <img src={Design} className="design-head-img" />
            <p className="clr-white design-desc">Our multi-domain design and technological capabilities enable us to provide high class experience.</p>
            <img src={Company} className="design-content-img"/>
          </div>
        </div>

        <div className="block-50">
          <img src={Banner1} className="banner1" />
        </div>
        
      </div>
      
      
      {/* CASE STUDY */}
      {/* <div className="caseCont">
        <div className="case-study wid-center">

          <div className="block-50">
            <div className="case-data-container">
              <img src={Case} className="case-img"/>
              <p className="case-heading">Experience our immersive live metaverse spaces.</p>
              <p className="clr-white case-para">Our multi domain design and technology capabilities enable us to provide high class experience.</p>
              <button className="butt-blue"
              onClick={() => {
                window.open('https://metafront.djvirtualevents.com/', '_blank');
              }}
              >Experience</button>
            </div>
          </div>

          <div className="block-50">
            <img src={Banner2} className="banner1"/>
          </div>
        </div>
      </div> */}
      
      
      {/* LEADERS */}
      <div className="leaders wid-center">
        <img src={Expert} className="leaders-head-img"/>
        <div className="leader-container">
          {profile.map( (data,index) => {
            return(
              <div className="indi-leader banner1">
                <img src={data.image} className="indi-leader-img" />
              </div>
            )
          })}
        </div>
      </div>
      
      {/* CONNECT US MESSAGE */}
      <div className="foot-message wid-center">
        <div className="foot-message-cont">
          <p className="clr-white foot-mess">Get in touch <a className="hyperLink" onClick={() => scrollToTargetAdjusted("form",0)}>with us.</a></p>
          {/* <button
          onClick={() => scrollToTargetAdjusted("form",0)}
          className="butt-blue contact-us-butt">Contact us</button> */}
        </div>
      </div>

      {/* FOOTER */}
      <div className="footer" id="aboutUs">
          <div className="wid-center foot-cont">
            <div className="block-50">
              <img src={Head} className="footer-main-img"/>
              <p className="clr-white foot-head">By DIGITAL JALEBI</p>
              <p className="clr-white foot-con-head">Get in touch.</p>
              <p className="clr-white foot-con-mail">harshit@digitaljalebi.com</p>
            </div>

            <div className="block-50 float-right block-footer ">
              <div className="float-right foot-data-cont block-footer">
                <p className="about-us">About Us</p>
                <p className="about-us-desc">We are a global metaverse development company with a decade long experience in crafting experiential technologies.We bridge design and technology to create powerful experiential solutions that empower our clients.</p>
                {/* <p className="clr-white foot-con-head">Get in touch.</p> */}
                {/* <p className="clr-white foot-con-mail">harshit@digitaljalebi.com</p> */}
                <div  className="social-icon-container">
                  {/* {social.map((data,index) =>{
                    return(
                      <a 
                      // href={data.link}
                      // target="_blank"
                      className="social-icon">
                        <img src={data.image} />
                      </a>
                    )
                  })} */}
                </div>
              </div>
            </div>
          </div>
      </div>

      <div className="footer-mobile" id="aboutUs">
        <img src={footerMobile} className="footer-mobile-img"/>
        <p className="about-us-mobile">About Us</p>
        <p className="about-us-desc-mobile">We are a global metaverse development company with a decade long experience in crafting experiential technologies.We bridge design and technology to create powerful experiential solutions that empower our clients.</p>
        
        <p className="clr-white foot-con-head">Get in touch.</p>
        <p className="clr-white foot-con-mail">harshit@digitaljalebi.com</p>
        {/* <p className="clr-white foot-con-head">Get in touch.</p> */}
        {/* <p className="clr-white foot-con-mail">harshit@digitaljalebi.com</p> */}
        <div  className="social-icon-container">
          {/* {social.map((data,index) =>{
            return(
              <a 
              // href={data.link}
              // target="_blank"
              className="social-icon">
                <img src={data.image} />
              </a>
            )
          })} */}
        </div>
      </div>
    </div>
  );
}

export default MainPage;
