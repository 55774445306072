import './App.css';
import Head from "./assets2/head.png"
import Ring from "./assets2/backRing.png"
import Events from "./assets2/Events.png"
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
  gtmId: 'GTM-KRP4BVC'
}
TagManager.initialize(tagManagerArgs)

const FormSubmit = () =>
{
  window.dataLayer.push({
    event: 'pageview'
  });
    return(
    <div className="main-page" style={{overflow: 'hidden',width: '100%',maxHeight:""}}>
        {/* HEADER */}
        <div className="header" style={{minHeight: '100vh'}}>
            <div className="navbar wid-center" />
              <div>
                <img src={Ring} className="ring" style={{top: "30vh"}}/>
                <img src={Head} className="head1" style={{marginTop: "25vh"}}/>
                <img src={Events} className="head2" />
              </div>
              <div className="thanks-message-cont">
                  <p className="thanks-message-head clr-white">Thank you!</p>
                  <p className="thanks-message-body clr-white">Our Representive will respond within the next 24 hrs.</p>
              </div>
            </div>
    </div>
    )
}


export default FormSubmit;