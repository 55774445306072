export const scrollToTargetAdjusted = (id, offsetY) => {
    var element = document.getElementById(id);
    var headerOffset = offsetY;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };